import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/[locale]/LandingHero.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["AttractionListSection"] */ "/workspace/src/components/contentSections/AttractionListSection.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/contentSections/BlogSliderSection.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["BlogSliderSectionSwiper"] */ "/workspace/src/components/contentSections/BlogSliderSectionSwiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CitiesSliderSection"] */ "/workspace/src/components/contentSections/CitiesSliderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CountriesSection"] */ "/workspace/src/components/contentSections/CountriesSection.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/typography/Typography.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/contentSections/sliderSection/SliderSection.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/contentSections/FlexHeaderSection.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/contentSections/FlexSubSection.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/contentSections/FlexSection.module.sass");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/unfortunate.module.sass");
