'use client';

import React, {useState} from "react";
import {Cities} from "@/utils/directus.types";
import {useEntityTranslator} from "@/utils/i18n-helpers";
import {ButtonData, SectionFilterButtons} from "./SectionFilterButtons";
import {directusImageAuto, takeRegions} from "@/utils/directus";
import {Swiper, SwiperSlide} from "swiper/react";
import style from "./CitiesSliderSection.module.sass";
import {Link} from "@/navigation";
import {cityLink} from "@/utils/links";
import {Navigation} from "swiper/modules";
import {FlexSliderSection} from "@/components/contentSections/sliderSection/FlexSliderSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {useTranslations} from "next-intl";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import NoPhoto from "@/components/placeholders/NoPhoto.tsx";

function CityCard({city}: { city: Cities }) {
  const et = useEntityTranslator();
  return <Link href={cityLink(city)}>
    <div className={style['cardSpace']}>
      <div className={style['cardContent']}>
        {city.preview_image
          ? <img src={`${directusImageAuto(city.preview_image.image, 271, 412)}`}
                 alt={et(city.preview_image, 'alt')}
                 title={et(city.preview_image, 'title')}/> : <NoPhoto/>}
        <span className={style['label']}>{et(city, 'title')}</span>
      </div>
    </div>
  </Link>
}

export function CitiesSliderSection({cities, title, filter, className}: {
  cities: Cities[],
  title: string,
  filter?: boolean,
  className?: string
}) {
  const t = useTranslations();
  const world = {
    id: null,
    slug: 'world',
    translations: [{languages_code: 'en', title: t('sections.filters.world')}]
  };
  const [region, setRegion] = useState<ButtonData>(world);
  const selectableRegions = [world, ...takeRegions(cities.map(c => c.country))];
  const onSelect = (selected: ButtonData) => {
    setRegion(selected);
  }
  return <FlexSection className={className}>
    <FlexHeaderSection>
      {title}
    </FlexHeaderSection>
    {(filter ?? true) && <SectionFilterButtons options={selectableRegions} selected={region} onSelect={onSelect}/>}
    <FlexSliderSection namespace="cities-slider">
      <Swiper className={style['cards']} slidesPerView='auto' spaceBetween='0' centeredSlides={false}
              centeredSlidesBounds={false} modules={[Navigation]}
              navigation={{prevEl: '#prev-cities-slider', nextEl: '#next-cities-slider'}}>
        {cities.filter(c => region.id ? c.country.region?.id === region.id : true)
          .map(c => <SwiperSlide key={c.id} className={style['card']}>
            <CityCard city={c}/>
          </SwiperSlide>)}
      </Swiper>
    </FlexSliderSection>
  </FlexSection>
}
