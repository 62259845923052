'use client';

import React, {useState} from "react";
import {Countries} from "@/utils/directus.types";
import {directusImageAuto, takeRegions} from "@/utils/directus";
import {useEntityTranslator} from "@/utils/i18n-helpers";
import {ButtonData, SectionFilterButtons} from "./SectionFilterButtons";
import style from './CountriesSection.module.sass';
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "@/navigation";
import {countryLink} from "@/utils/links";
import {Navigation} from "swiper/modules";
import {FlexSliderSection} from "@/components/contentSections/sliderSection/FlexSliderSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {useTranslations} from "next-intl";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import NoPhoto from "@/components/placeholders/NoPhoto.tsx";
import unfortunate from "@/styles/unfortunate.module.sass";

function CountryCard({country}: { country: Countries }) {
  const et = useEntityTranslator();
  return <Link href={countryLink(country)}>
    <div className={style['cardSpace']}>
      <div className={style['cardContent']}>
        {country.preview_image
          ? <img src={`${directusImageAuto(country.preview_image.image, 372, 277)}`}
                 alt={et(country.preview_image, 'alt')}
                 title={et(country.preview_image, 'title')}/>
          : <NoPhoto/>}
        <span className={style['label']}>{et(country, 'title')}</span>
      </div>
    </div>
  </Link>
}

export function CountriesSection({countries}: { countries: Countries[] }) {
  const t = useTranslations();
  const world = {
    id: null,
    slug: 'world',
    translations: [
      {languages_code: 'en', title: t('sections.filters.world')},
    ]
  };
  const [region, setRegion] = useState<ButtonData>(world);
  const selectableRegions = [world, ...takeRegions(countries)];
  const onSelect = (selected: ButtonData) => {
    setRegion(selected);
  }
  return <FlexSection className={unfortunate['centerHeader']}>
    <FlexHeaderSection>
      {t('sections.countries.header')}
    </FlexHeaderSection>
    <SectionFilterButtons options={selectableRegions} selected={region} onSelect={onSelect}/>
    <FlexSliderSection namespace="country-slide">
      <Swiper className={style['cards']} slidesPerView='auto' centeredSlides={false} centeredSlidesBounds={false}
              spaceBetween='0' modules={[Navigation]}
              navigation={{prevEl: '#prev-country-slide', nextEl: '#next-country-slide'}}>
        {countries.filter(c => region.id ? c.region?.id === region.id : true)
          .map(c => <SwiperSlide key={c.id} className={style['card']}>
            <CountryCard country={c}/>
          </SwiperSlide>)}
      </Swiper>
    </FlexSliderSection>
  </FlexSection>
}
