import React, {ReactElement} from "react";
import style from "./SliderSection.module.sass";
import {classList} from "@/utils/lib.ts";

type content = false | null | undefined | ReactElement;

export function FlexSliderSection({children, className, namespace, hideControls}: {
  className?: string,
  children: content | content[],
  namespace: string,
  hideControls?: boolean,
}) {
  return <section className={`${style['section']} ${className}`}>
    <div className={style['side']}>
      {!hideControls && <button id={`prev-${namespace}`} className={classList(style['button'], style['left'])}/>}
    </div>
    <div className={style['content']}>
      {children}
    </div>
    <div className={style['side']}>
      {!hideControls && <button id={`next-${namespace}`} className={style['button']}/>}
    </div>
  </section>
}